<template>
  <div>
    <v-alert
      icon="mdi-account"
      dense
      dismissible
      type="warning"
      v-if="resent"
      class="tw-mb-0 tw-rounded-none tw-fixed tw-max-w-460 tw-z-10 tw-fixed tw-top-0"
    >
      <p class="tw-mb-0">
        We’ve re-sent your link to
        <span class="tw-font-bold">{{ user.email }}</span
        >. Be sure to check your promotions and other folders.
      </p>
    </v-alert>
    <v-col class="tw-p-0">
      <h2
        class="tw-text-lg tw-text-left tw-font-semibold tw-text-charcoal tw-px-10"
      >
        Email
      </h2>
      <div class="tw-py-7 tw-px-10">
        <div class="tw-flex tw-flex-row tw-justify-between tw-items-start">
          <div class="tw-flex tw-flex-row tw-items-start tw-justify-start">
            <img
              src="@/assets/svgs/profile/email-notifications.svg"
              alt="Email Notifications"
              class="tw-mr-4 tw-w-6"
            />
            <p
              class="tw-text-black tw-text-sm tw-max-w-200 tw-text-left tw--mt-1 tw-mb-0"
            >
              Email me trip updates to stay in the know.
            </p>
          </div>
          <v-switch
            color="secondary"
            hide-details
            v-model="receiveEmails"
            @change="saveNotificationSetting('receiveEmails')"
            inset
            class="tw-ml-2 tw--mt-1 c-switch"
          />
        </div>
        <div
          class="tw-flex tw-items-start tw-justify-between tw-mt-4 tw-px-3"
          v-if="!user.isEmailVerified"
        >
          <p
            class="tw-text-j-red tw-m-0 tw-text-left tw-mr-4 tw-text-xs tw-ml-7"
          >
            Oops, you need to click the link in your<br />
            email to verify!
          </p>
          <a
            href="#"
            @click.prevent="resend()"
            class="tw-text-j-red tw-font-medium tw-underline tw-text-sm"
            >Resend</a
          >
        </div>
      </div>
      <hr class="tw-mx-10" />
      <div class="tw-py-7 tw-px-10">
        <div class="tw-flex tw-flex-row tw-justify-between tw-items-start">
          <div class="tw-flex tw-flex-row tw-items-start tw-justify-start">
            <img
              src="@/assets/svgs/profile/travel-deals-notifications.svg"
              alt="Email Notifications"
              class="tw-mr-3 tw-w-7"
            />
            <p
              class="tw-text-black tw-text-sm tw-max-w-200 tw-text-left tw--mt-1 tw-mb-0"
            >
              Email me about new features and exclusive offers.
            </p>
          </div>
          <v-switch
            color="secondary"
            hide-details
            v-model="receiveMarketingEmails"
            @change="saveNotificationSetting('receiveMarketingEmails')"
            inset
            class="tw-ml-2 tw--mt-1 c-switch"
          />
        </div>
      </div>
      <div class="tw-bg-separator-grey tw-w-full tw-h-2 tw-mb-10"></div>
    </v-col>
    <v-col class="tw-p-0">
      <h2
        class="tw-text-lg tw-text-left tw-font-semibold tw-text-charcoal tw-px-10"
      >
        Text / SMS
      </h2>
      <p
        class="tw-m-0 tw-text-black tw-text-sm tw-text-left tw-px-10 tw-mt-2 tw-mb-4"
      >
        We recommend keeping these messages on so we can provide you the best
        experience.
      </p>
      <hr class="tw-mx-10" />
      <div class="tw-py-7 tw-px-10">
        <div class="tw-flex tw-flex-row tw-justify-between tw-items-start">
          <div class="tw-flex tw-flex-row tw-items-start tw-justify-start">
            <img
              src="@/assets/svgs/profile/sms-notifications.svg"
              alt="SMS Notifications"
              class="tw-mr-4 tw-w-5"
            />
            <p
              class="tw-text-black tw-text-sm tw-max-w-200 tw-text-left tw--mt-1 tw-mb-0"
            >
              Text me about trip updates so I don’t miss anything.
            </p>
          </div>
          <v-switch
            color="secondary"
            hide-details
            v-model="receiveSMS"
            @change="saveNotificationSetting('receiveSMS')"
            inset
            class="tw-ml-2 tw--mt-1 c-switch"
          />
        </div>
      </div>
      <div
        class="tw-flex tw-items-center tw-justify-between tw-px-14 tw-mb-7"
        v-if="!user.isPhoneVerified"
      >
        <p class="tw-text-j-red tw-text-left tw-m-0 tw-mr-4 tw-text-xs tw-ml-5">
          Oops, you need to verify your number!
        </p>
        <a
          href="#"
          @click.prevent="openEditProfile()"
          class="tw-text-j-red tw-font-medium tw-underline tw-text-sm"
          >Verify</a
        >
      </div>

      <div class="tw-bg-separator-grey tw-w-full tw-h-2 tw-mb-10"></div>
    </v-col>
    <v-col class="tw-p-0">
      <div class="tw-px-10">
        <h2
          class="tw-text-lg tw-text-left tw-font-semibold tw-text-charcoal tw-mb-8"
        >
          What you'll get
        </h2>
        <div class="tw-flex tw-items-start tw-mt-4 tw-mb-2">
          <img
            src="@/assets/svgs/crew_updates.svg"
            alt="Crew updates"
            class="tw-mr-4"
          />
          <div class="tw-text-left tw--mt-1">
            <p class="tw-m-0 tw-font-semibold tw-text-charcoal">Crew Updates</p>
            <p class="tw-text-light-grey tw-text-sm">
              Know who’s going. See all posts and reactions from other crew
              members.
            </p>
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-mt-4 tw-mb-2">
          <img
            src="@/assets/svgs/finalized_details.svg"
            alt="Finalized details"
            class="tw-mr-4"
          />
          <div class="tw-text-left tw--mt-1">
            <p class="tw-m-0 tw-font-semibold tw-text-charcoal">
              Finalized Details
            </p>
            <p class="tw-text-light-grey tw-text-sm">
              Get notified when trip details become finalized by the crew.
            </p>
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-mt-4 tw-mb-2">
          <img
            src="@/assets/svgs/friendly_reminders.svg"
            alt="Friendly Reminders"
            class="tw-w-10 md:tw-w-8 tw-mr-4"
          />
          <div class="tw-text-left tw--mt-1">
            <p class="tw-m-0 tw-font-semibold tw-text-charcoal">
              Friendly Reminders
            </p>
            <p class="tw-text-light-grey tw-text-sm">
              We’ll send you reminders to keep travel plans on track.
            </p>
          </div>
        </div>
      </div>
    </v-col>
  </div>
</template>
<script>
export default {
  name: "Notifications",
  props: ["user"],
  data() {
    return {
      receiveSMS: this.user.receiveSMS,
      receiveEmails: this.user.receiveEmails,
      receiveMarketingEmails: this.user.receiveMarketingEmails,
      resent: false
    };
  },
  methods: {
    async resend() {
      this.resent = false;
      await this.$store
        .dispatch("user-verify-email-resend/create", {
          email: this.user.email
        })
        .then(() => {
          this.resent = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openEditProfile() {
      this.$store.commit("meta/setClickedVerificationBanner", true);
      if (this.$route.name !== "EditProfile")
        this.$router.push({ name: "EditProfile" });
    },
    async saveNotificationSetting(property) {
      const updatedUser = {
        [property]: this[property]
      };
      await this.$store.dispatch("users/patch", [this.user.id, updatedUser]);
      this.$store.dispatch("meta/showGlobalAlert", {
        type: "success",
        text: "Notification settings were updated.",
        timeout: 2000
      });
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Notifications",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });
  },
  destroyed() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: false
    });
  }
};
</script>
<style lang="scss">
.c-switch.v-input--is-label-active .secondary--text {
  color: #6db184 !important;
  opacity: 1;
}

.c-switch.v-input--is-label-active.v-input--switch--inset
  .v-input--switch__thumb {
  color: #fff !important;
}
</style>
